/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'
import { TabSelector } from '../../components/TabSelector'

// Store
import { KioskStoreContext } from '../../stores/KioskStore'
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getEventKioskKPIs } from '../../services/kiosks.service'
import { getKioskConfiguration } from '../../services/firestore/firestore.service'

// Style and Utils
import colors, { CHART_COLORS } from '../../utils/colors'

const FILTER_OPTIONS = [
  { id: 'category', label: 'Category' },
  { id: 'location', label: 'Location' },
  { id: 'day', label: 'Day' },
]

/**
 * Custom border for the bars to accommodate overlapped, rounded corners from design.
 * These borders display at the top of the out-of-the-box bars from nivo's bar chart.
 */
const CustomBorder = ({ bars }) =>
  bars.map((bar) => {
    const thisDayAttendees = bars.filter((b) => b.x === bar.x && b.data.value !== 0)

    const hasNoValue = bar.data.value === 0 || bar.data.value === null
    const isOnlyValue = bar.data.value !== 0 && thisDayAttendees.length === 1
    const isTopMostValue = !hasNoValue && bar.y === Math.min(...thisDayAttendees.map((b) => b.y))

    // Adjust height based on the number of bars in the group and if there are values
    let height = hasNoValue || isOnlyValue || isTopMostValue ? 0 : 16
    if (bar.height < 16 && !isOnlyValue && !isTopMostValue && !hasNoValue) {
      height = bar.height / 2 + 8
    }
    return (
      <rect
        key={bar.key}
        x={bar.x}
        y={bar.y - 8}
        ry={0}
        rx={0}
        width={bar.width}
        height={height}
        fill={bar.color}
      />
    )
  })

const CATEGORY_COLORS = [
  colors.purple.light,
  colors.purple.DEFAULT,
  colors.purple[900],
  colors.purple.dark,
  colors.blue.light,
  colors.blue.DEFAULT,
  colors.blue[900],
  colors.blue.dark,
  colors.teal.light,
  colors.teal.DEFAULT,
  colors.teal[900],
  colors.teal.dark,
]

/**
 *
 * LeadKPIs
 *
 */
const LeadKPIs = ({ eventKPIs, lastUpdated }) => {
  // State
  const [leadsPerDay, setLeadsPerDay] = useState([])
  const [totalScans, setTotalScans] = useState(0)

  useEffect(() => {
    if (Object.keys(eventKPIs).length !== 0 && leadsPerDay?.length === 0) {
      const leadsPerDayArray = Object.entries(eventKPIs?.leadsPerDay).map((entry) => {
        const key = entry[0]
        const value = entry[1]
        return {
          date: key,
          leads: value,
        }
      })
      setLeadsPerDay(leadsPerDayArray)

      let scansCount = 0

      Object.entries(eventKPIs?.leadsPerDay).forEach((entry) => {
        scansCount += entry[1]
      })
      setTotalScans(scansCount)
    }
  }, [eventKPIs])

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full justify-between gap-4">
        <div className="flex h-28 basis-1/3 flex-col justify-between rounded-xl p-4 shadow-lg">
          <div className="flex h-12 items-baseline justify-center font-bold">
            <span className="text-5xl text-purple">{eventKPIs?.totalExhibitorCount}</span>
          </div>
          <div className="text-sm font-bold">Exhibiting Companies</div>
        </div>

        <div className="flex h-28 basis-1/3 flex-col justify-between rounded-xl p-4 shadow-lg">
          <div className="flex h-12 items-end justify-center text-center text-5xl font-bold text-purple">
            <span className="text-5xl font-bold text-purple">{eventKPIs?.rentalDeviceCount}</span>
          </div>
          <div className="text-sm font-bold">Devices Rented</div>
        </div>

        <div className="flex h-28 basis-1/3 flex-col justify-between rounded-xl p-4 shadow-lg">
          <div className="flex h-12 items-end justify-center text-4xl font-bold text-purple">
            <span>{eventKPIs?.appLicenseCount}</span>
          </div>
          <div className="text-sm font-bold">Licenses Purchased</div>
        </div>
      </div>

      <div className="h-72 rounded-xl p-4 shadow-lg">
        <div className="flex flex-col">
          <div>
            <div className="text-sm font-bold">Number of Scans</div>
            <div className="text-xs">The total number of scans per day</div>
          </div>
          <div className="flex h-48 w-full gap-12">
            <div className="basis-1/3 self-end">
              <div className="flex basis-1/3 flex-col justify-end gap-1 text-5xl font-bold text-purple">
                {totalScans}
              </div>
              <div className="text-sm font-bold">Total Scans</div>
            </div>
            <div className="flex h-48 basis-2/3">
              {leadsPerDay && (
                <ResponsiveBar
                  animate={false}
                  borderRadius="8px"
                  data={leadsPerDay}
                  keys={['leads']}
                  indexBy="date"
                  margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                  padding={0.4}
                  valueScale={{ type: 'linear' }}
                  colors={colors.purple.DEFAULT}
                  enableLabel={false}
                  axisBottom={{
                    format: (value) => dayjs(value, 'YYYYMMDD').format('M/D'),
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisLeft={null}
                  theme={{
                    grid: {
                      line: {
                        stroke: '#6EE5CD',
                        strokeDasharray: '4',
                      },
                    },
                    axis: {
                      ticks: {
                        text: {
                          fill: '#96959E',
                          fontSize: '12px',
                          fontWeight: '500',
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {lastUpdated && (
        <div className="mr-2 mt-[-10px] text-right text-xs text-gray-600">
          Last Update: {dayjs(lastUpdated).format('M/D/YY h:m:s A')}
        </div>
      )}
    </div>
  )
}

LeadKPIs.propTypes = {
  eventKPIs: PropTypes.object.isRequired,
  lastUpdated: PropTypes.instanceOf(Date).isRequired,
}

const CustomToolTip = ({ id, value }) => (
  <div className="rounded-lg border bg-white p-2 capitalize shadow-lg">
    <strong>{id}:</strong>
    <span> {value}</span>
  </div>
)

CustomToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

/**
 *
 * BadgingKPIs
 *
 */
const BadgingKPIs = ({ categories, eventKPIs, lastUpdated }) => {
  // State
  const [filterBy, setFilterBy] = useState('category')
  const [byLocation, setByLocation] = useState([])
  const [byCategory, setByCategory] = useState([])
  const [checkInsPerDay, setCheckInsPerDay] = useState([])
  const [categoryTotals, setCategoryTotals] = useState({})

  useEffect(() => {
    if (_.keys(eventKPIs).length !== 0 && checkInsPerDay?.length === 0) {
      /** Per Day */

      const checkInsPerDayArray = _.entries(eventKPIs?.checkInsByDay).map((entry) => {
        const key = entry[0]
        const value = entry[1]
        return {
          date: key,
          checkIns: value,
        }
      })
      setCheckInsPerDay(checkInsPerDayArray)

      /** Per Category */

      // Configure bar chart data
      const days = _.keys(eventKPIs.checkInsByCategory)
      const checkInsByCategory = days.map((day, index) => ({
        id: day,
        color:
          index < CHART_COLORS.length
            ? CHART_COLORS[index]
            : CHART_COLORS[index % CHART_COLORS.length],
        day,
        ...eventKPIs.checkInsByCategory[day],
      }))

      // Calculate the total check-ins for each category
      const totals = {}
      categories.forEach((category) => {
        totals[category] = 0
      })

      // Calculate the total check-ins for each category
      days.forEach((day) => {
        const dayCategories = eventKPIs.checkInsByCategory[day]
        categories.forEach((category) => {
          totals[category] += dayCategories[category] || 0
        })
      })

      if (checkInsByCategory.length > 0) {
        setCategoryTotals(totals)
        setByCategory(checkInsByCategory)
      }

      /** Per Location */

      const checkInsByLocation = _.entries(eventKPIs?.checkInsByLocation).map((entry, index) => ({
        id: entry[0],
        label: entry[0],
        value: entry[1],
        color:
          index < CHART_COLORS.length
            ? CHART_COLORS[index]
            : CHART_COLORS[index % CHART_COLORS.length],
      }))
      setByLocation(checkInsByLocation)
    }
  }, [eventKPIs])

  const renderCheckedInPercentage = () => {
    if (eventKPIs?.checkedInAttendeeCount && eventKPIs?.totalAttendeeCount) {
      return `${((eventKPIs.checkedInAttendeeCount / eventKPIs.totalAttendeeCount) * 100).toFixed(
        2,
      )}%`
    }
    return '0%'
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full justify-between gap-4">
        <div className="flex h-28 basis-1/2 flex-col justify-between rounded-xl p-4 shadow-lg">
          <div className="flex items-baseline justify-center font-bold">
            <span className="text-5xl text-purple">{eventKPIs?.checkedInAttendeeCount}</span>
            <span className="text-2xl">/{eventKPIs?.totalAttendeeCount}</span>
          </div>
          <div className="text-sm font-bold">Total Checked-in</div>
        </div>

        <div className="flex h-28 basis-1/2 flex-col justify-between rounded-xl p-4 shadow-lg">
          <div className="text-center text-5xl font-bold text-purple">
            {renderCheckedInPercentage()}
          </div>
          <div className="text-sm font-bold">Checked-in %</div>
        </div>
      </div>

      <div className="flex h-72 flex-col rounded-xl p-4 shadow-lg">
        <div className="flex items-center gap-4">
          <div className="text-sm font-bold">Check Ins</div>
          <Select
            className="rounded-2xl border-0 py-2.5 pr-4 shadow-none placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            options={FILTER_OPTIONS}
            onChange={(selectedOption) => {
              // Handle selected option
              setFilterBy(selectedOption.id)
            }}
            value={_.find(FILTER_OPTIONS, { id: filterBy })}
            style={{ width: '200px' }}
          />
        </div>
        <div className="flex">
          {filterBy === 'category' && (
            <div className="flex h-48 w-full justify-between gap-12">
              <div className="flex h-full basis-48 overflow-y-auto">
                <div className="mt-auto flex flex-1 flex-col gap-1 ">
                  {categories.map((category, index) => (
                    <div key={category} className="flex justify-between">
                      <div className="flex gap-2">
                        <div
                          className="h-3.5 w-[14px] rounded"
                          style={{
                            backgroundColor: CATEGORY_COLORS[index],
                          }}
                        />
                        <div className="text-xs capitalize">{category}</div>
                      </div>
                      <div className="text-xs">{categoryTotals[category]}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex basis-2/3">
                {byCategory && (
                  <ResponsiveBar
                    animate={false}
                    borderRadius="8px"
                    data={byCategory}
                    keys={categories}
                    indexBy="day"
                    layers={['grid', 'axes', CustomBorder, 'bars']}
                    margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                    padding={0.4}
                    valueScale={{ type: 'linear' }}
                    colors={CATEGORY_COLORS}
                    enableLabel={false}
                    axisBottom={{
                      format: (value) => dayjs(value, 'YYYYMMDD').format('M/D'),
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={null}
                    tooltip={CustomToolTip}
                    theme={{
                      grid: {
                        line: {
                          stroke: '#6EE5CD',
                          strokeDasharray: '4',
                        },
                      },
                      axis: {
                        ticks: {
                          text: {
                            fill: '#96959E',
                            fontSize: '12px',
                            fontWeight: '500',
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {filterBy === 'location' && (
            <div className="flex h-48 w-full justify-between gap-12">
              <div className="flex h-full basis-48 overflow-y-auto">
                <div className="mt-auto flex flex-1 flex-col gap-1">
                  {byLocation.map((location) => (
                    <div key={location.date} className="flex justify-between space-x-4">
                      <div className="flex gap-2">
                        <div
                          className="h-3.5 w-[14px] rounded"
                          style={{ backgroundColor: location.color }}
                        />
                        <div className="text-xs">{location.label}</div>
                      </div>
                      <div className="text-xs">{location.value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex basis-2/3 justify-center">
                <div className="h-48 w-48">
                  <ResponsivePie
                    colors={{ datum: 'data.color' }}
                    data={
                      byLocation.length > 0 && _.some(byLocation, (l) => l.value > 0)
                        ? byLocation
                        : [
                            {
                              id: 'No Data',
                              label: 'No Data',
                              value: 100,
                              color: '#E0E0E0',
                            },
                          ]
                    }
                    innerRadius={0.8}
                    isInteractive={byLocation.length > 0}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                  />
                </div>
              </div>
            </div>
          )}

          {filterBy === 'day' && (
            <div className="flex h-48 w-full justify-between gap-12">
              <div className="flex h-full basis-48 overflow-y-auto">
                <div className="mt-auto flex flex-1 flex-col gap-1">
                  {checkInsPerDay.map((day) => (
                    <div key={day.date} className="flex justify-between">
                      <div className="flex gap-2">
                        <div
                          className="h-3.5 w-[14px] rounded"
                          style={{ backgroundColor: colors.purple.DEFAULT }}
                        />
                        <div className="text-xs">{dayjs(day.date, 'YYYYMMDD').format('M/D')}</div>
                      </div>
                      <div className="text-xs">{day.checkIns}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex h-48 basis-2/3">
                {checkInsPerDay && (
                  <ResponsiveBar
                    animate={false}
                    borderRadius="8px"
                    data={checkInsPerDay}
                    keys={['checkIns']}
                    indexBy="date"
                    margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                    padding={0.4}
                    valueScale={{ type: 'linear' }}
                    colors={colors.purple.DEFAULT}
                    enableLabel={false}
                    axisBottom={{
                      format: (value) => dayjs(value, 'YYYYMMDD').format('M/D'),
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={null}
                    theme={{
                      grid: {
                        line: {
                          stroke: '#6EE5CD',
                          strokeDasharray: '4',
                        },
                      },
                      axis: {
                        ticks: {
                          text: {
                            fill: '#96959E',
                            fontSize: '12px',
                            fontWeight: '500',
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {lastUpdated && (
        <div className="mr-2 mt-[-10px] text-right text-xs text-gray-600">
          Last Update: {dayjs(lastUpdated).format('M/D/YY h:m:s A')}
        </div>
      )}
    </div>
  )
}

BadgingKPIs.defaultProps = {
  categories: [],
}

BadgingKPIs.propTypes = {
  categories: PropTypes.array,
  eventKPIs: PropTypes.object.isRequired,
  lastUpdated: PropTypes.instanceOf(Date).isRequired,
}

const SettingsModal = observer(({ capturingScan, categories, setShow, show }) => {
  // Context
  const { KPIs, KPIsLastUpdated, location, name, setKPIs, updateKiosk } =
    useContext(KioskStoreContext)
  const { event, organizationId } = useContext(NavigationStoreContext)
  const navigate = useNavigate()

  // State
  const [deviceLocations, setDeviceLocations] = useState([])
  const [deviceName, setDeviceName] = useState(name)

  useEffect(() => {
    const loadData = async () => {
      const getKPIs = async () => {
        const response = await getEventKioskKPIs(event.id)
        if (response) setKPIs(response, new Date())
      }
      getKPIs()

      // Get kiosk device locations
      const kioskConfiguration = await getKioskConfiguration(event.id)
      if (kioskConfiguration?.kioskDeviceLocations) {
        setDeviceLocations(
          _.map(kioskConfiguration.kioskDeviceLocations, (d) => ({ id: d.id, label: d.name })),
        )
      }
    }

    loadData()

    setDeviceName(name)
  }, [])

  const handleSavingKioskSettings = async (data) => {
    updateKiosk(data.location, 'attendee', data.name)
  }

  const tabs = [
    {
      name: 'Badging KPIs',
      id: 'badging',
      content: (
        <BadgingKPIs categories={categories} eventKPIs={KPIs} lastUpdated={KPIsLastUpdated} />
      ),
    },
  ]

  if (event.enableLeadRetrieval) {
    tabs.push({
      name: 'Leads KPIs',
      id: 'leads',
      content: <LeadKPIs eventKPIs={KPIs} lastUpdated={KPIsLastUpdated} />,
    })
  }

  return (
    <Modal
      actions={null}
      className="w-[72%] sm:max-w-[1034px]"
      onClose={() => {}}
      open={show}
      setOpen={() => {}}
      content={
        <div className="flex flex-col">
          <span className="text-xl font-semibold">Kiosk Settings</span>

          <div className="mt-3 flex w-full flex-row gap-6">
            <div className="flex w-full max-w-[258px] flex-col space-y-3">
              <Select
                className="rounded-lg border-gray-400 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="location"
                id="location"
                label="Device Location"
                onChange={(v) => {
                  handleSavingKioskSettings({
                    location: v,
                    name,
                  })
                }}
                options={deviceLocations}
                placeholder="Select a Device Location"
                style={{ flex: true, width: '100%' }}
                value={location}
              />

              <TextInput
                className="rounded-lg border-gray-400 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="name"
                fullWidth
                nunito
                label="Device Name"
                placeholder="Enter Device Name"
                onChange={(e) => {
                  if (capturingScan) return
                  setDeviceName(e.target.value)
                }}
                onBlur={() => {
                  // If the device name is the same as the current name, do nothing
                  if (deviceName === name) return

                  // If the device name is empty, set it to the current name
                  if (deviceName === '') {
                    setDeviceName(name)
                    return
                  }

                  // Save the new device name
                  handleSavingKioskSettings({
                    name: deviceName,
                    location,
                  })
                }}
                value={deviceName}
              />
            </div>

            <div className="flex w-full flex-col">
              {KPIs && <TabSelector tabStyle="tabs" tabs={tabs} center />}
            </div>
          </div>

          <div className="mt-7 flex w-full justify-center gap-4">
            <div className="flex justify-between gap-2">
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                label="Launch Attendee Mode"
                onClick={() => {
                  updateKiosk(location, 'attendee', name)
                  setShow(false)
                }}
              />

              <Button
                className="border-black bg-black hover:bg-black-light"
                label="Launch Staff Mode"
                onClick={() => {
                  updateKiosk(location, 'staff', name)
                  setShow(false)
                }}
              />

              <Button
                background="bg-white"
                label="Exit Kiosk Mode"
                onClick={() =>
                  navigate(`/organization/${organizationId}/event/${event.id}/check-in/settings`)
                }
              />
            </div>
          </div>
        </div>
      }
    />
  )
})

SettingsModal.defaultProps = {
  capturingScan: false,
  categories: [],
}

SettingsModal.propTypes = {
  capturingScan: PropTypes.bool,
  categories: PropTypes.array,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export { SettingsModal }
