import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

// Components
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'

// Images
import Edit from '../../assets/images/editCircle.svg'
import Location from '../../assets/images/location.svg'
import Memo from '../../assets/images/memo.svg'
import Suitcase from '../../assets/images/suitcase.svg'
import User from '../../assets/images/user.svg'

// Service
import { addQuickBadge } from '../../services/firestore/firestore.service'

const BASE_FIELDS = [
  'firstName',
  'lastName',
  'companyName',
  'title',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'zipCode',
  'first_name',
  'last_name',
  'company_name',
  'address_1',
  'address_2',
  'zip_code',
]

const DEFAULT = {
  firstName: '',
  lastName: '',
  category: null,
  companyName: '',
  title: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
}

const QuickBadgeModal = ({
  baseQuickBadge,
  eventId,
  handleCheckIn,
  registrationSyncData,
  closeModal,
}) => {
  // State
  const [categories, setCategories] = useState([])
  const [customFieldKeys, setCustomFieldKeys] = useState([])
  const [customFields, setCustomFields] = useState({})

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  useEffect(() => {
    if (registrationSyncData && !_.isEmpty(registrationSyncData.attendeeCategories)) {
      // Load attendee category options
      const { attendeeCategories } = registrationSyncData

      // Configure category options
      const categoryOptions = _.map(_.keys(attendeeCategories), (k) => ({
        id: k,
        label: _.capitalize(attendeeCategories[k]),
      }))
      setCategories(_.sortBy(categoryOptions))

      // Add utilized custom fields to the form
      const { utilizedCustomFields } = registrationSyncData

      // Filter out custom fields that are on the base attendee object
      // and are available in the custom fields
      const additionalFields = _.filter(
        utilizedCustomFields,
        (f) =>
          !BASE_FIELDS.includes(f) &&
          registrationSyncData.availableCustomFields &&
          registrationSyncData.availableCustomFields[f],
      )
      // Remove the fields that have options
      const fieldsWithOptions = _.remove(
        additionalFields,
        (f) => registrationSyncData.availableCustomFields[f].options,
      )

      // Create an object with the custom fields that have options
      // We need to convert the options to an array of objects with id and label
      const customFieldsWithOptions = {}
      _.forEach(fieldsWithOptions, (f) => {
        customFieldsWithOptions[f] = _.map(
          _.values(registrationSyncData.availableCustomFields[f].options),
          (o) => ({ id: o, label: o }),
        )
      })

      setCustomFields({ withoutOptions: additionalFields, withOptions: customFieldsWithOptions })
    }
  }, [registrationSyncData])

  useEffect(() => {
    if (baseQuickBadge) {
      const quickBadge = { ...baseQuickBadge }

      if (registrationSyncData && !_.isEmpty(categories) && !_.isEmpty(customFields)) {
        // Extract custom data from the quick badge
        const customData = { ...quickBadge.customData }
        delete quickBadge.customData

        // Save custom field keys
        setCustomFieldKeys(_.keys(customData))

        // Set custom data fields (minus category)
        _.forEach(customData, (v, k) => {
          if (customFields.withOptions[k]) {
            const matchingOption = _.find(customFields.withOptions[k], (o) => o.id === v)
            quickBadge[k] = matchingOption
          } else {
            quickBadge[k] = v
          }
        })

        // Set category object for prefilling
        quickBadge.category = _.find(categories, (c) => c.id === quickBadge.categoryId)
      }

      reset(quickBadge)
    }
  }, [baseQuickBadge, registrationSyncData, categories, customFields])

  /**
   * Handles creating the quick badge data.
   * @param {object} data
   */
  const onSubmit = (data) => {
    // Extract custom data from the form data so we can transform any values as needed
    const customDataKeys = _.keys(data).filter((k) => customFieldKeys.includes(k))
    const customData = _.pick(data, customDataKeys)

    // Any values that are objects need to be converted
    const updatedCustomData = _.mapValues(customData, (c) => {
      if (_.isObject(c)) {
        return c.id
      }

      return c
    })

    // Custom data gets nested on the attendee
    const baseData = _.pick(
      data,
      _.keys(data).filter((k) => BASE_FIELDS.includes(k)),
    )
    const attendee = {
      ...baseData,
      categoryId: data.category?.id || '',
      categoryName: data.category?.label || '',
      customData: updatedCustomData,
      id: `${uuidv4()}`,
    }

    // Create quick badge
    addQuickBadge(eventId, attendee)

    // Kick off badge print
    handleCheckIn({ ...attendee, quickBadge: true })

    // Reset the form
    reset(DEFAULT)
    closeModal()
  }

  const renderExtraFields = () => {
    const { withoutOptions, withOptions } = customFields
    return (
      <div className="space-y-4">
        {_.map(withoutOptions, (field) => (
          <TextInput
            key={field}
            className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
            fullWidth
            inputStyles="rounded-none"
            name={field}
            nunito
            label={_.capitalize(_.startCase(field))}
            placeholder={_.capitalize(_.startCase(field))}
            {...register(field)}
          />
        ))}

        {_.map(withOptions, (options, field) => (
          <Controller
            key={field}
            name={field}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                className="rounded-2xl border-gray-550 py-2.5 pl-3 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
                fullWidth
                name={field}
                nunito
                onChange={onChange}
                options={options}
                label={_.capitalize(_.startCase(field))}
                placeholder={`Select ${_.capitalize(_.startCase(field))}`}
                style={{ flex: true, width: '100%' }}
                value={value}
              />
            )}
          />
        ))}
      </div>
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: () => {
            setTimeout(() => {
              reset(DEFAULT)
              closeModal(false)
            }, 500)
          },
        },
        {
          type: 'submit',
          label: 'Print Quick Badge',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      icon={<img src={Edit} alt="Add" />}
      open
      content={
        <div className="mt-3 h-[65vh] max-h-[600px] flex-col space-y-4 overflow-y-auto text-center sm:mt-5">
          {/* Attendee Category */}

          {registrationSyncData && (
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pl-3 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
                  error={errors.category && 'This field is required'}
                  fullWidth
                  name="category"
                  nunito
                  onChange={onChange}
                  options={categories}
                  label="Category"
                  placeholder="Select a Category"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
            />
          )}

          {/* Base Attendee Fields (required) */}

          <div className="flex flex-row gap-2">
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
              error={errors.firstName && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="firstName"
              nunito
              label="First Name"
              placeholder="First Name"
              {...register('firstName', { required: true })}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
              error={errors.lastName && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="lastName"
              nunito
              label="Last Name"
              placeholder="Last Name"
              {...register('lastName', { required: true })}
            />
          </div>

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Company" className="ml-1.5 h-4" src={Suitcase} />}
            error={errors.companyName && 'This field is required'}
            fullWidth
            inputStyles="rounded-none"
            name="companyName"
            nunito
            label="Company"
            placeholder="Company"
            {...register('companyName')}
          />

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Title" className="ml-1.5 h-4" src={Memo} />}
            error={errors.title && 'This field is required'}
            fullWidth
            inputStyles="rounded-none"
            name="title"
            nunito
            label="Title"
            placeholder="Title"
            {...register('title')}
          />

          {/* Base Attendee Fields (not-required) */}

          <TextInput
            fullWidth
            className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
            error={errors.address1 && 'This field is required'}
            label="Address"
            labelStyles="rounded-none"
            name="address1"
            nunito
            placeholder="Address"
            {...register('address1')}
          />

          <TextInput
            fullWidth
            className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
            error={errors.address2 && 'This field is required'}
            label="Apartment, Suite, etc."
            labelStyles="rounded-none"
            name="address2"
            nunito
            placeholder="Apartment, Suite, etc."
            {...register('address2')}
          />

          <div className="flex flex-row gap-2">
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
              error={errors.city && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="city"
              nunito
              label="City"
              placeholder="City"
              {...register('city')}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
              error={errors.state && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="state"
              nunito
              label="State"
              placeholder="State"
              {...register('state')}
            />
          </div>

          <div className="flex flex-row gap-2">
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
              error={errors.country && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="country"
              nunito
              label="Country"
              placeholder="Country"
              {...register('country')}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-8 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
              error={errors.zipCode && 'This field is required'}
              fullWidth
              inputStyles="rounded-none"
              name="zipCode"
              nunito
              label="Zip Code"
              placeholder="Zip Code"
              {...register('country')}
            />
          </div>

          {/* Custom Data Fields */}
          {registrationSyncData && renderExtraFields()}
        </div>
      }
      title="Create Quick Badge"
    />
  )
}

QuickBadgeModal.defaultProps = {
  baseQuickBadge: null,
  registrationSyncData: null,
}

QuickBadgeModal.propTypes = {
  baseQuickBadge: PropTypes.object,
  eventId: PropTypes.string.isRequired,
  handleCheckIn: PropTypes.func.isRequired,
  registrationSyncData: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
}

export default QuickBadgeModal
