import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { EventHeader } from '../../components/EventHeader'
import { StateContainer } from '../../components/StateContainer'

// Local Components
import { DesignSettings } from './DesignSettings'
import { KioskSettings } from './KioskSettings'
import { Popups } from './Popups'

// Assets
import Badger from '../../assets/images/badger_white.svg'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils & Service
import { addKioskConfiguration, getKioskConfiguration } from '../../services/kiosks.service'
import { joinClassNames } from '../../utils/helpers'
import Tooltip from '../../components/Tooltip/Tooltip'

const TABS = [
  {
    id: 0,
    label: 'Design',
  },
  {
    id: 1,
    label: 'Kiosk Settings',
  },
  {
    id: 3,
    label: 'Pop-Ups',
  },
]

const DEFAULT_KIOSK_CONFIG = {
  useDefaultBrand: true,
  backgroundColor: '#ffffff',
  fontColor: '#000000',
  googleFont: null,
  customFont: null,
  headerImage: null,
  headerBase64: null,
  logoImage: null,
  logoBase64: null,
  footerImage: null,
  footerBase64: null,
  adminPassword: null,
  useVerification: false,
  verificationField: null,
  useExternalPopupField: false,
  externalPopupField: null,
}

const EventSettings = observer(() => {
  // Context
  const navigate = useNavigate()
  const { event, organizationId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [active, setActive] = useState(TABS[0])
  const [kioskConfiguration, setKioskConfiguration] = useState(event.kioskConfiguration)

  useEffect(() => {
    if (!event.kioskConfiguration) {
      const createKioskConfiguration = async () => {
        const response = await addKioskConfiguration(
          event.id,
          DEFAULT_KIOSK_CONFIG,
          () => {},
          () => {},
        )

        if (response) {
          setKioskConfiguration(response)
          setEvent({ ...event, kioskConfiguration: response.id })
        }
      }

      createKioskConfiguration()
    } else {
      const getKioskConfig = async () => {
        const response = await getKioskConfiguration(
          event.id,
          event.kioskConfiguration.id || event.kioskConfiguration,
          () => {},
          () => {},
        )

        if (response) {
          setKioskConfiguration(response)
        }
      }

      getKioskConfig()
    }
  }, [])

  /**
   * Renders the active tab content.
   */
  const renderTabContent = () => {
    switch (active.id) {
      case 0:
        return (
          <DesignSettings
            event={event}
            kioskConfiguration={kioskConfiguration}
            setKioskConfiguration={setKioskConfiguration}
          />
        )
      case 1:
        return (
          <KioskSettings
            event={event}
            kioskConfiguration={kioskConfiguration}
            setKioskConfiguration={setKioskConfiguration}
          />
        )
      case 3:
        return (
          <Popups
            event={event}
            kioskConfiguration={kioskConfiguration}
            setKioskConfiguration={setKioskConfiguration}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-5 overflow-y-hidden py-3">
          <div className="flex flex-col items-start justify-between space-y-1 px-3 sm:flex-row sm:space-y-0 md:items-center">
            <EventHeader event={event} />

            <Tooltip
              content={
                <div className="rounded-lg bg-white px-2 pb-0.5">
                  <span className="text-xs">Badge Configuration is deprecated.</span>
                </div>
              }
              display={event?.badgeConfiguration?.usesDeprecatedConfig}
              placement="left"
            >
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                disabled={event?.badgeConfiguration?.usesDeprecatedConfig}
                icon={<img src={Badger} alt="" className="h-5" />}
                label="Launch Badgr"
                onClick={() => navigate(`/organization/${organizationId}/event/${event.id}/kiosk`)}
              />
            </Tooltip>
          </div>

          <div className="h-full w-full">
            <div className="px-3">
              <nav className="flex" aria-label="Tabs">
                {TABS.map((tab) => {
                  const activeTab = active.id === tab.id

                  return (
                    <button
                      type="button"
                      key={tab.label}
                      onClick={() => setActive(tab)}
                      className={joinClassNames(
                        activeTab
                          ? 'border-purple-700 bg-[#8c56f626] text-purple-700'
                          : 'border-b-transparent text-gray-600 hover:border-purple-700 hover:bg-[#8c56f626] hover:text-purple-700',
                        'group relative flex w-1/3 items-center justify-center rounded-t-[10px] border-b-2 px-4 py-2 text-sm font-medium',
                      )}
                      aria-current={activeTab ? 'page' : undefined}
                    >
                      {tab.label}
                    </button>
                  )
                })}
              </nav>
            </div>

            <div className="shadow-xm flex h-[80%] w-full flex-col overflow-hidden rounded-[15px] bg-white px-5 py-4 tall:h-[90%]">
              {kioskConfiguration && renderTabContent()}
            </div>
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default EventSettings
