import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getSessionsList } from '../../services/events.service'

// Utils & Styles
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * EventSessions
 *
 */
const EventSessions = observer(() => {
  // Context
  const { eventId } = useParams()
  const navigate = useNavigate()
  const { organizationId } = useContext(NavigationStoreContext)

  // State
  const [sessions, setSessions] = useState([])

  // Pagination
  const [totalRows, setTotalRows] = useState(0)
  const [pages, setPages] = useState({ next: null, previous: null })
  const [perPage, setPerPage] = useState(10)
  const [loadingSessions, setLoadingSessions] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  // Search
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('')
  const searchInputRef = useRef(null)

  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of sessions; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedSessionsList = async (url) => {
    const response = await getSessionsList(url, handleErrors, setLoadingSessions, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setSessions(response.results)
    }
  }

  useEffect(() => {
    if (filter) {
      getUpdatedSessionsList(`/events/${eventId}/sessions/?limit=${perPage}&${filter}`)
    } else {
      getUpdatedSessionsList(`/events/${eventId}/sessions/?limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterSessions = useCallback(_.debounce(updateSearch, 500), [])

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div>
            <span className="text-md font-bold">Sessions</span>
          </div>

          <div className="flex flex-row items-center justify-between space-x-2">
            <TextInput
              className="w-full rounded-full py-2.5 pl-10 pr-4  placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterSessions('')
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterSessions(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              type="search"
              value={searchTerm}
            />

            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              icon={<PlusIcon className="h-5 sm:h-6" />}
              label="Add Session"
              onClick={() => {
                console.log('Add Session')
              }}
            />
          </div>

          <DataTable
            columns={[
              {
                id: 'type',
                grow: 0.25,
                name: 'Type',
                selector: (row) => row.type,
                cell: (row) => (
                  <div className="flex items-center space-x-2">
                    <span
                      className={mergeClassNames(
                        'rounded-full px-2.5 py-0.5 text-xs font-bold text-white',
                        row.type === 'Access Control'
                          ? 'bg-status-green text-role-admin'
                          : 'bg-purple',
                      )}
                    >
                      {row.type === 'Access Control' ? 'AC' : 'DC'}
                    </span>
                  </div>
                ),
                sortable: true,
                sortBy: 'type',
              },
              {
                id: 'name',
                grow: 1,
                name: 'Session Name',
                selector: (row) => row.name,
                cell: (row) => (
                  <button
                    className="font-bold text-purple hover:text-black"
                    key={`event:${row.id}`}
                    onClick={() => {
                      navigate(
                        `/organization/${organizationId}/event/${eventId}/sessions/${row.id}/`,
                      )
                    }}
                    type="button"
                  >
                    {row.name}
                  </button>
                ),
                minWidth: '250px',
                sortable: true,
                sortBy: 'name',
              },
              {
                id: 'date',
                grow: 1,
                name: 'Date',
                selector: (row) => dayjs(row.startsAt).format('MM/DD/YYYY'),
                minWidth: '125px',
              },
              {
                id: 'startsAt',
                grow: 1,
                name: 'Start Time',
                selector: (row) => dayjs(row.startsAt).format('hh:mm A'),
                minWidth: '125px',
              },
              {
                id: 'endsAt',
                grow: 1,
                name: 'End Time',
                selector: (row) => dayjs(row.endsAt).format('hh:mm A'),
                minWidth: '125px',
              },
              {
                id: 'location',
                grow: 1,
                name: 'Location',
                selector: (row) => row.location,
                minWidth: '125px',
              },
            ]}
            data={sessions}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedSessionsList,
                `/events/${eventId}/sessions/?limit=`,
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              getUpdatedSessionsList(
                `/events/${eventId}/sessions/?order_by=${d}${column.sortBy}&limit=${perPage}`,
              )
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingSessions}
            sortServer
          />
        </div>
      </StateContainer>
    </div>
  )
})

export default EventSessions
