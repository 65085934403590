import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PlusIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import _ from 'lodash'

// Images
import Badger from '../../assets/images/badger.svg'
import Email from '../../assets/images/email.svg'
import Lead from '../../assets/images/fetch.svg'
import Trackr from '../../assets/images/trackr.svg'
import User from '../../assets/images/user.svg'

// Components
import { AddEventModal } from '../../components/AddEventModal'
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { DataTile } from '../../components/DataTile'
import { EditIcon } from '../../components/EditIcon'
import { ExhibitorIconSolid } from '../../components/ExhibitorIconSolid'
import { LocationIconSolid } from '../../components/LocationIconSolid'
import { Modal } from '../../components/Modal'
import { PhoneNumberInput } from '../../components/PhoneNumberInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { WelcomeTile } from '../../components/WelcomeTile'
import { UsersIcon } from '../../components/UsersIcon'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import { getEvent, getEventsForOrganization } from '../../services/events.service'
import { updateExhibitor } from '../../services/exhibitors.service'
import { getOrganizationKpis } from '../../services/organizations.service'
import { createTransaction } from '../../services/purchases.service'

// Utils & Styles
import baseColors, {
  configureActiveBackgroundStyles,
  configureActiveTextStyle,
} from '../../utils/colors'
import { handlePagination, toast } from '../../utils/helpers'
import { PurchaseLicensesModal } from '../../components/PurchaseLicensesModal'
import { getChargeData, getPricingData } from '../../components/PurchaseLicensesModal/helpers'

dayjs.extend(customParseFormat)

/**
 *
 * OrganizationDashboard
 *
 */
const OrganizationDashboard = observer(() => {
  // Context
  const { organizationId, setEventId, type } = useContext(NavigationStoreContext)
  const { isEEUser } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const onboardingEventId = searchParams.get('onboarding')

  // State
  const [loading, setLoading] = useState(true)
  const [loadingPurchase, setLoadingPurchase] = useState(false)
  const [error, setError] = useState(null)
  const [kpis, setKpis] = useState(null)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [events, setEvents] = useState([])
  const [showEventModal, setShowEventModal] = useState(false)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)
  const [showLicensesModal, setShowLicensesModal] = useState(false)
  const [showFreeLicensesModal, setShowFreeLicensesModal] = useState(false)
  const [onboardingEvent, setOnboardingEvent] = useState(null)
  const [pricingInfo, setPricingInfo] = useState({
    firstPrice: 0,
    additionalPrice: 0,
    rentalPrice: 0,
  })

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  /**
   * Manually add the `group` className to each event row
   * so that group styles apply to the custom event name cell.
   */
  useEffect(() => {
    if (events) {
      // Add a very short timeout to ensure the table is displayed
      // before we modify the row styling.
      setTimeout(() => {
        const eventRows = document.querySelectorAll('.rdt_TableRow')
        eventRows.forEach((row) => {
          // Add className for `group`
          if (!row.classList.contains('group')) row.classList.add('group')
        })
      }, [300])
    }
  }, [events])

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  })

  const { handleSubmit: handleSubmitOnboarding } = useForm()

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of events; updates pagination.
   * @param {string} url
   * @param {boolean} skipLoading
   * @returns list of results
   */
  const getUpdatedEventList = async (url, skipLoading) => {
    const response = await getEventsForOrganization(
      url,
      null,
      handleErrors,
      skipLoading ? () => {} : setLoadingEvents,
      () => {},
    )

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })

      setEvents(response.results)
      return response.results
    }

    return []
  }

  /**
   * Handles submitting contact information for onboarding an exhibitor to an event.
   * @param {object} data
   */
  const onSubmitContactInformation = async (data) => {
    const updatedExhibitor = await updateExhibitor(
      onboardingEvent.id,
      {
        id: onboardingEvent.eventExhibitor.id,
        contactFirstName: data.firstName,
        contactLastName: data.lastName,
        contactEmail: data.email,
        contactPhone: data.phone,
      },
      handleErrors,
      setLoading,
      () => {},
    )

    if (updatedExhibitor) {
      handleSuccess('Contact information set.')

      const { licenseCounts } = updatedExhibitor

      // If the exhibitor has free licenses, show the free licenses modal
      if (licenseCounts.numAllotedFreeLicenses > 0 && licenseCounts.numValidFreeLicenses === 0) {
        setShowFreeLicensesModal(true)
      }
      // If the exhibitor hasn't purchased any licenses yet and has no free licenses, show the licenses modal
      else if (
        licenseCounts.numValidPaidLicenses === 0 &&
        licenseCounts.numValidFreeLicenses === 0
      ) {
        setShowLicensesModal(true)
      }
      // Otherwise, mark as onboarded and navigate to the event home page
      else {
        await onSubmitOnboarding()

        setEventId(onboardingEvent.id)
        navigate(`/exhibitor/${organizationId}/event/${onboardingEvent.id}/home`)
      }

      setShowOnboardingModal(false)
    }
  }

  /**
   * Handles marking the exhibitor as having completed onboarding for the event.
   */
  const onSubmitOnboarding = async () =>
    updateExhibitor(
      onboardingEvent.id,
      {
        id: onboardingEvent.eventExhibitor.id,
        hasCompletedOnboarding: true,
      },
      handleErrors,
      setLoading,
      () => {
        handleSuccess(`Successfully onboarded for ${onboardingEvent.name}.`)
      },
    )

  /**
   * Handles submitting the purchase of licenses for an event.
   * @param {object} data
   */
  const onSubmitLicensePurchase = async (data) => {
    const chargeData = await getChargeData(data, pricingInfo, 0)

    await createTransaction(
      onboardingEvent.id,
      onboardingEvent.eventExhibitor.id,
      chargeData,
      handleErrors,
      setLoadingPurchase,
      () => {
        handleSubmitOnboarding(onSubmitOnboarding)()
        setShowLicensesModal(false)

        // Navigate to the event home page
        setEventId(onboardingEvent.id)
        navigate(`/exhibitor/${organizationId}/event/${onboardingEvent.id}/home`)
      },
    )
  }

  useEffect(() => {
    const loadData = async () => {
      // Get organization KPIs
      const result = await getOrganizationKpis(
        organizationId,
        setError,
        () => {},
        () => {},
      )
      setKpis(result)
      setLoading(false)
    }

    loadData()
  }, [organizationId])

  /**
   * When the filter or the row count changes, get the updated event list.
   */
  useEffect(() => {
    getUpdatedEventList(`/organizations/${organizationId}/events/?limit=${perPage}`)
  }, [perPage])

  useEffect(() => {
    if (onboardingEventId && events) {
      const startOnboarding = async () => {
        const match = _.find(events, { id: onboardingEventId })

        if (!match) return

        // Get event information to retrieve pricing info
        const eventToOnboard = await getEvent(organizationId, match.id, handleErrors, () => {})

        if (!eventToOnboard.eventExhibitor.hasCompletedOnboarding) {
          const pricing = getPricingData(eventToOnboard)
          setPricingInfo(pricing)

          // Show onboarding modal
          setOnboardingEvent(match)
          setShowOnboardingModal(true)
        } else if (eventToOnboard.eventExhibitor.hasCompletedOnboarding) {
          setEventId(match.id)
          navigate(`/exhibitor/${organizationId}/event/${match.id}/home`)
        }
      }

      startOnboarding()
    }
  }, [onboardingEventId, events])

  /**
   * Renders the `Status` cell in the DataTable
   * - Displays a pill label based on event `status`
   * @param {object} row
   */
  const renderStatusCell = (row) => (
    <div
      className={mergeClassNames(
        'shrink-0 rounded-full px-2.5 py-0.5',
        configureActiveBackgroundStyles(row),
      )}
    >
      <span
        className={mergeClassNames('text-xs font-bold', configureActiveTextStyle(row))}
        data-tag="allowRowEvents"
      >
        {row.status}
      </span>
    </div>
  )

  const columns = [
    {
      id: 'name',
      grow: 2,
      name: 'Event Name',
      selector: (row) => row.name,
      cell: (row) => (
        <>
          <div className="flex w-full flex-col space-y-1">
            <div className="flex w-full flex-row place-items-center space-x-2">
              <span className="truncate text-sm font-medium text-black-500 group-hover:text-teal">
                {row.name}
              </span>

              {renderStatusCell(row)}
            </div>

            <div className="flex w-full flex-row place-items-center text-gray-500 group-hover:text-white">
              {row.numAttendees !== null && (
                <div className="flex basis-[15%] flex-row place-items-center space-x-2">
                  <UsersIcon className="fill-gray-500 group-hover:fill-white" />
                  <span>{row.numAttendees}</span>
                </div>
              )}

              {row.numExhibitors !== null && (
                <div className="flex basis-[15%] flex-row place-items-center space-x-2">
                  <ExhibitorIconSolid className="fill-gray-500 group-hover:fill-white" />
                  <span>{row.numExhibitors}</span>
                </div>
              )}

              {row.location && (
                <div className="flex basis-[70%] flex-row place-items-center space-x-2">
                  <LocationIconSolid className="fill-gray-500 group-hover:fill-white" />
                  <span>{row.location}</span>
                </div>
              )}
            </div>
          </div>
          {/*
            This element is necessary for the onRowClicked handler to function
            without having to add the `data-tag` to the innermost elements in this custom cell
            more info here: https://react-data-table-component.netlify.app/?path=/docs/api-props--page#general-properties
          */}
          <div
            style={{ height: '100%', position: 'absolute', width: '100%' }}
            data-tag="allowRowEvents"
          ></div>
        </>
      ),
      sortBy: 'name',
      sortable: true,
      minWidth: '300px',
    },
    {
      id: 'dates',
      cell: (row) => (
        <div className="group-hover:text-white">
          <span data-tag="allowRowEvents">{row.dateRange}</span>
        </div>
      ),
      grow: 1,
      name: 'Dates',
      selector: (row) => row.dateRange,
      sortable: true,
      sortBy: 'starts_at',
      minWidth: '180px',
    },
  ]

  if (type === 'organizer-organization') {
    columns.push({
      id: 'stack',
      center: true,
      width: '100px',
      name: 'Stack',
      cell: (row) => {
        const stack = []

        if (row.enableLeadRetrieval)
          stack.push(
            <img
              alt="lead retrieval"
              className="h-6"
              key={`event:${row.id}:lead`}
              src={Lead}
              data-tag="allowRowEvents"
            />,
          )
        if (row.enableBadging)
          stack.push(
            <img
              alt="badging"
              className="h-6"
              key={`event:${row.id}:badging`}
              src={Badger}
              data-tag="allowRowEvents"
            />,
          )
        if (import.meta.env.VITE_TRACKR_ENABLED === 'true' && row.enableTrackr)
          stack.push(
            <img alt="tracker" className="h-7" key={`event:${row.id}:tracker`} src={Trackr} />,
          )

        return (
          <div className="flex w-full justify-center space-x-2" data-tag="allowRowEvents">
            {stack}
          </div>
        )
      },
    })
  }

  useEffect(() => {
    if (onboardingEvent && onboardingEvent.eventExhibitor) {
      reset({
        ...onboardingEvent,
        firstName: onboardingEvent.eventExhibitor.contactFirstName,
        lastName: onboardingEvent.eventExhibitor.contactLastName,
        email: onboardingEvent.eventExhibitor.contactEmail,
        phone: onboardingEvent.eventExhibitor.contactPhoneNumber,
      })
    }
  }, [onboardingEvent])

  return (
    <div className="h-full w-full">
      <StateContainer error={error} loading={loading}>
        <div className="relative flex h-full w-full flex-col space-y-4 overflow-y-auto p-3">
          <span className="ml-5 text-2xl font-semibold">Dashboard</span>
          <WelcomeTile type={type} />

          {kpis && type === 'organization' && (
            <div className="flex w-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <DataTile color="purple" label="Total Events" value={totalRows} />

              <DataTile label="Active Events" value={kpis.activeEventCount} />

              <DataTile color="red" label="Closed Events" value={kpis.closedEventCount} />
            </div>
          )}

          <div className="h-full w-full">
            <div className="mb-4 flex w-full flex-row place-items-center justify-between">
              <span className="ml-5 text-2xl font-semibold">Events</span>

              {type === 'organization' && isEEUser && (
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  icon={<PlusIcon className="h-5 sm:h-6" />}
                  label="Add Event"
                  onClick={() => setShowEventModal(true)}
                />
              )}
            </div>

            <DataTable
              columns={columns}
              customStyles={{
                responsiveWrapper: {
                  style: {
                    border: `1px solid ${baseColors.gray.light}`,
                    borderRadius: '8px',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                  },
                },
                head: {
                  style: {
                    height: '40px',
                  },
                },
                headRow: {
                  style: {
                    backgroundColor: baseColors.gray[100],
                    textTransform: 'uppercase',
                    minHeight: '40px',
                    borderBottomColor: baseColors.gray.light,
                  },
                },
                pagination: {
                  style: {
                    color: baseColors.gray[900],
                    fontSize: '12px',
                    minHeight: '30px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontWeight: '600',
                  },
                  pageButtonsStyle: {
                    borderRadius: '50%',
                    height: '32px',
                    width: '32px',
                    padding: '4px',
                    margin: 'px',
                    cursor: 'pointer',
                    transition: '0.4s',
                    outline: 'none',
                    color: 'black',
                    fill: 'black',
                    backgroundColor: 'transparent',
                    '&:disabled': {
                      cursor: 'unset',
                      color: 'black',
                      opacity: 0.25,
                      fill: 'black',
                    },
                    '&:hover:not(:disabled)': {
                      backgroundColor: baseColors.purple.DEFAULT,
                      fill: 'white',
                    },
                    '&:focus': {
                      outline: 'none',
                      backgroundColor: baseColors.purple.DEFAULT,
                      fill: 'white',
                    },
                  },
                },
                rows: {
                  style: {
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: baseColors.gray[600],
                    height: '70px',
                    '&:not(:last-of-type)': {
                      borderBottomColor: baseColors.gray.light,
                    },
                    '&:hover': {
                      backgroundColor: baseColors.gray[900],
                      color: baseColors.white,
                      cursor: 'pointer',
                    },
                  },
                },
              }}
              data={events}
              defaultSortFieldId="dates"
              defaultSortAsc
              onRowClicked={async (row) => {
                if (row.eventExhibitor?.hasCompletedOnboarding || type !== 'exhibitor') {
                  setEventId(row.id)
                  navigate(
                    `/${
                      type === 'exhibitor' ? 'exhibitor' : 'organization'
                    }/${organizationId}/event/${row.id}/home`,
                  )
                } else {
                  // Get event information to retrieve pricing info
                  const eventToOnboard = await getEvent(
                    organizationId,
                    row.id,
                    handleErrors,
                    () => {},
                  )

                  const pricing = getPricingData(eventToOnboard)
                  setPricingInfo(pricing)

                  // Show onboarding modal
                  setOnboardingEvent(row)
                  setShowOnboardingModal(true)
                }
              }}
              onChangePage={(page) =>
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedEventList,
                  `/organizations/${organizationId}/events/?limit=`,
                )
              }
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                const url = `/organizations/${organizationId}/events/?order_by=${d}${column.sortBy}&limit=${perPage}`
                getUpdatedEventList(url)
              }}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loadingEvents}
              sortServer
            />
          </div>
        </div>
      </StateContainer>

      {showEventModal && (
        <AddEventModal
          loadData={() =>
            getUpdatedEventList(`/organizations/${organizationId}/events/?limit=${perPage}`)
          }
          organization={organizationId}
          setShowEventModal={setShowEventModal}
        />
      )}

      {showOnboardingModal && (
        <Modal
          actions={[
            {
              type: 'cancel',
              label: 'Cancel',
              onClick: () => {
                setShowOnboardingModal(false)
              },
            },
            {
              type: 'submit',
              label: 'Save',
              onClick: handleSubmit(onSubmitContactInformation),
            },
          ]}
          icon={<EditIcon className="h-5 fill-white sm:h-6" />}
          content={
            <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
              <div className="flex flex-col sm:flex-row sm:space-x-2">
                <TextInput
                  className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  icon={<img alt="User" className="ml-2 h-4" src={User} />}
                  error={errors.firstName && 'This field is required'}
                  fullWidth
                  id="firstName"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  name="firstName"
                  nunito
                  label="First Name"
                  placeholder="First Name"
                  {...register('firstName', { required: true })}
                />
                <TextInput
                  className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  icon={<img alt="User" className="ml-2 h-4" src={User} />}
                  error={errors.lastName && 'This field is required'}
                  fullWidth
                  id="lastName"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  name="lastName"
                  nunito
                  label="Last Name"
                  placeholder="Last Name"
                  {...register('lastName', { required: true })}
                />
              </div>
              <TextInput
                className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
                error={errors.email && 'This field is required'}
                fullWidth
                id="email"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="email"
                nunito
                label="Email"
                placeholder="Email"
                {...register('email', { required: true })}
              />
              <PhoneNumberInput
                control={control}
                disabled={loading}
                errors={errors}
                label="Phone"
                name="phone"
              />
            </div>
          }
          loading={loading}
          open={showOnboardingModal}
          title="Confirm Event Contact"
        />
      )}

      {showFreeLicensesModal && (
        <Modal
          actions={[
            {
              type: 'cancel',
              label: 'Back',
              onClick: () => {
                setShowOnboardingModal(true)
              },
            },
            {
              type: 'submit',
              label: 'Activate',
              onClick: () => {
                handleSubmitOnboarding(onSubmitOnboarding)()
                setShowFreeLicensesModal(false)

                // Navigate to the event home page
                setEventId(onboardingEvent.id)
                navigate(`/exhibitor/${organizationId}/event/${onboardingEvent.id}/home`)
              },
            },
          ]}
          icon={<EditIcon className="h-5 fill-white sm:h-6" />}
          content={
            <div className="mt-3 sm:mt-5">
              <div>
                <span className="text-sm text-gray-600">
                  Congrats! You have received{' '}
                  <strong>{`${onboardingEvent.eventExhibitor.numFreeLicensesOverride} FREE`}</strong>{' '}
                  lead retrieval licenses. You may purchase additional licenses or rental devices
                  in your Exhibitor Portal. Click &quot;Activate&quot; below to start building out
                  your Lead Retrieval App!
                </span>
              </div>
            </div>
          }
          loading={loadingPurchase}
          open={showFreeLicensesModal}
          setOpen={setShowFreeLicensesModal}
          title="Activate License(s)"
        />
      )}

      {showLicensesModal && (
        <PurchaseLicensesModal
          event={onboardingEvent}
          loading={loadingPurchase}
          onSubmit={onSubmitLicensePurchase}
          showModal={showLicensesModal}
          setShowModal={setShowLicensesModal}
          licenses={[]}
        />
      )}
    </div>
  )
})

export default OrganizationDashboard
