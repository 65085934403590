import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { CurrencyDollarIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'

// Images
import Add from '../../assets/images/add.svg'
import Badger from '../../assets/images/badger.svg'
import Edit from '../../assets/images/editCircle.svg'
import Lead from '../../assets/images/fetch.svg'
import Location from '../../assets/images/location.svg'
import Ticket from '../../assets/images/ticket.svg'
import Trackr from '../../assets/images/trackr.svg'

// Components
import { Button } from '../Button'
import { CheckBox } from '../CheckBox'
import { DatePicker } from '../DatePicker'
import { FileUploader } from '../FileUploader'
import { Modal } from '../Modal'
import { RichTextInput } from '../RichTextInput'
import { Select } from '../Select'
import { TextInput } from '../TextInput'
import { Toggle } from '../Toggle'

// Utils & Service
import { addEvent, updateEvent } from '../../services/events.service'
import { toast } from '../../utils/helpers'

const DEFAULT = {
  displayAttendeeCount: true,
  displayExhibitorCount: true,
  enableRegistrationSync: false,
  enableLeadRetrieval: false,
  enableBadging: false,
  enableTrackr: false,
  endsAt: null,
  location: '',
  name: '',
  organization: null,
  percentRevenueShare: 0,
  startsAt: null,
  techNotes: '<p><br></p>',
  timezone: { id: 'America/Denver', label: 'America/Denver' },
}

const TIMEZONES = _.map(Intl.supportedValuesOf('timeZone'), (t) => ({ id: t, label: t }))

const AddEventModal = ({
  editEvent,
  loadData,
  organization,
  organizations,
  setShowEventModal,
}) => {
  // State
  const [loading, setLoading] = useState(false)
  const [showStartDate, setShowStartDate] = useState(false)
  const [showEndDate, setShowEndDate] = useState(false)
  const [uploadLogo, setUploadLogo] = useState(null)
  const [logo, setLogo] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [fileLoaded, setFileLoaded] = useState(false)
  const [fileUploadWarning, setFileUploadWarning] = useState(false)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      displayAttendeeCount: editEvent?.displayAttendeeCount || DEFAULT.displayAttendeeCount,
      displayExhibitorCount: editEvent?.displayExhibitorCount || DEFAULT.displayExhibitorCount,
      enableRegistrationSync: editEvent?.enableRegistrationSync || DEFAULT.enableRegistrationSync,
      enableLeadRetrieval: editEvent?.enableLeadRetrieval || DEFAULT.enableLeadRetrieval,
      enableBadging: editEvent?.enableBadging || DEFAULT.enableBadging,
      enableTrackr: editEvent?.enableTrackr || DEFAULT.enableTrackr,
      endsAt: editEvent?.endsAt || DEFAULT.endsAt,
      location: editEvent?.location || DEFAULT.location,
      name: editEvent?.name || DEFAULT.name,
      organization: editEvent?.organization || DEFAULT.organization,
      percentRevenueShare: editEvent?.percentRevenueShare || DEFAULT.percentRevenueShare,
      startsAt: editEvent?.startsAt || DEFAULT.startsAt,
      techNotes: editEvent?.techNotes || DEFAULT.techNotes,
      timezone: editEvent
        ? _.find(TIMEZONES, (t) => t.id === editEvent.timezone)
        : DEFAULT.timezone,
    },
  })

  useEffect(() => {
    if (!fileLoaded && fileUploadWarning) {
      setFileUploadWarning(false)
    }
  }, [fileLoaded])

  /**
   * Handles submitting the add event form.
   * @param {object} data
   */
  const onSubmit = (data) => {
    // If the file is loaded and not uploaded, show the warning
    if (uploadLogo) {
      setFileUploadWarning(true)
      return
    }

    // Reset the warning
    setFileUploadWarning(false)

    // Submit the form
    const updatedData = { ...data, timezone: data.timezone.id }
    updatedData.startsAt = dayjs(data.startsAt).format('YYYY-MM-DD')
    updatedData.endsAt = dayjs(data.endsAt).format('YYYY-MM-DD')

    if (logo !== null) {
      updatedData.logoUrl = logo.url
      updatedData.logoThumbnailUrl = thumbnail.url
    }

    if (editEvent) {
      updatedData.id = editEvent.id
      updateEvent(updatedData, handleErrors, setLoading, (m) => {
        loadData()
        handleSuccess(m)
        setShowEventModal(false)
      })
    } else {
      // If the organization id is passed in, use it
      if (organization) {
        updatedData.organizer = organization
      }
      // Otherwise, use the organization id from the selected organization
      else {
        updatedData.organizer = data.organization.id
      }

      delete updatedData.organization

      addEvent(updatedData, handleErrors, setLoading, (m) => {
        loadData()
        handleSuccess(m)
        setShowEventModal(false)
      })
    }
  }

  const renderLogo = () => {
    if (uploadLogo || !editEvent || editEvent.signedLogoUrl === null || logo) {
      return (
        <div>
          <FileUploader
            showImagePreviewAfterUpload
            acceptedFileTypes={['image/*']}
            allowResize
            allowRevert
            fileLoaded={fileLoaded || (editEvent && editEvent?.signedLogoUrl !== null)}
            generateThumbnail
            setFileLoaded={(s) => setFileLoaded(s)}
            handleUploadToServer={async (file) => {
              if (file.name.includes('thumb')) {
                setThumbnail(file)
              } else {
                setLogo(file)
              }

              setFileLoaded(false)
              setUploadLogo(false)
            }}
            id="logo"
            imageCropAspectRatio="1:1"
            type="gcp"
          />

          {fileUploadWarning && (
            <div className="w-full bg-error-light px-2 py-1">
              <span className="text-sm font-medium text-error-dark">
                {!uploadLogo
                  ? 'This field is required'
                  : 'Please upload your logo first or cancel the upload.'}
              </span>
            </div>
          )}
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4">
        {editEvent.signedLogoThumbnailUrl && (
          <img
            src={editEvent.signedLogoThumbnailUrl}
            alt="Logo"
            className="h-12 w-12 rounded-full object-fill"
          />
        )}

        <Button
          background="bg-white"
          label={(editEvent && editEvent.signedLogoThumbnailUrl) || logo ? 'Change' : 'Upload'}
          onClick={() => setUploadLogo(true)}
          outlined
        />
      </div>
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: () => {
            setTimeout(() => {
              reset(DEFAULT)
            }, 500)
          },
        },
        {
          type: 'submit',
          label: editEvent ? 'Save' : 'Add Event',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      icon={<img src={editEvent ? Edit : Add} alt={editEvent ? 'Edit' : 'Add'} />}
      content={
        <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
          <div className="flex w-full flex-col">
            <label
              htmlFor="logo"
              className="self-start pb-1 font-nunito text-sm font-medium text-gray-700"
            >
              Logo
            </label>

            {renderLogo()}
          </div>

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Event" className="ml-1.5 h-5" src={Ticket} />}
            data-testid="name"
            disabled={loading}
            error={errors.name && 'This field is required'}
            fullWidth
            id="name"
            inputStyles="rounded-none"
            name="name"
            nunito
            label="Name of Event"
            placeholder="Name of Event"
            {...register('name', { required: true })}
          />

          {!organization && (
            <Controller
              name="organization"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="organization"
                  disabled={loading}
                  error={errors.organization && 'This field is required'}
                  fullWidth
                  id="organization"
                  name="organization"
                  nunito
                  onChange={onChange}
                  options={organizations}
                  label="Organization"
                  placeholder="Select an Organization"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <div className="mb-1 flex flex-col space-y-4 sm:mb-4 sm:flex-row sm:space-x-2 sm:space-y-0">
            <Controller
              name="startsAt"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  disabled={loading}
                  error={errors.startsAt && (errors.startsAt.message || 'This field is required')}
                  label="Start Date"
                  name="startsAt"
                  onChange={onChange}
                  show={showStartDate}
                  setShow={setShowStartDate}
                  value={value}
                />
              )}
              rules={{
                required: true,
                validate: (value, values) => {
                  if (values.endsAt && dayjs(value).isAfter(dayjs(values.endsAt))) {
                    return 'Start date must be before end date'
                  }

                  return null
                },
              }}
            />

            <Controller
              name="endsAt"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  disabled={loading}
                  error={errors.endsAt && (errors.endsAt.message || 'This field is required')}
                  label="End Date"
                  name="endsAt"
                  onChange={onChange}
                  show={showEndDate}
                  setShow={setShowEndDate}
                  value={value}
                />
              )}
              rules={{
                required: true,
                validate: (value, values) => {
                  if (values.startsAt && dayjs(value).isBefore(dayjs(values.startsAt))) {
                    return 'End date must be after start date'
                  }

                  return null
                },
              }}
            />
          </div>

          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="timezone"
                disabled={loading}
                error={errors.timezone && 'This field is required'}
                fullWidth
                id="timezone"
                name="timezone"
                nunito
                onChange={onChange}
                options={TIMEZONES}
                label="Timezone"
                placeholder="Select a Timezone"
                search
                style={{ flex: true, width: '100%' }}
                value={value}
              />
            )}
            rules={{ required: true }}
          />

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Location" className="ml-1.5 h-5" src={Location} />}
            data-testid="location"
            disabled={loading}
            error={errors.location && 'This field is required'}
            fullWidth
            id="location"
            inputStyles="rounded-none"
            name="location"
            nunito
            label="Location"
            placeholder="Location"
            {...register('location', { required: true })}
          />

          <div className="mb-1 flex w-full flex-col place-content-center gap-3 sm:mb-4 sm:flex-row">
            <Controller
              name="enableLeadRetrieval"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Toggle
                  className="mt-4 self-center"
                  disabled={loading}
                  label={
                    <div className="flex flex-row items-center space-x-2">
                      <span className="text-sm">Lead Retrieval</span>
                      <img alt="lead retrieval" className="h-6" src={Lead} />
                    </div>
                  }
                  name="enableLeadRetrieval"
                  onChange={onChange}
                  checked={value}
                  ref={ref}
                />
              )}
            />

            <Controller
              name="enableBadging"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Toggle
                  className="mt-4 self-center"
                  disabled={loading}
                  label={
                    <div className="flex flex-row items-center space-x-2">
                      <span className="text-sm">Badging</span>
                      <img alt="badging" className="h-6" src={Badger} />
                    </div>
                  }
                  name="enableBadging"
                  onChange={onChange}
                  checked={value}
                  ref={ref}
                />
              )}
            />

            {import.meta.env.VITE_TRACKR_ENABLED === 'true' && (
              <Controller
                name="enableTrackr"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Toggle
                    className="mt-4 self-center"
                    disabled={loading}
                    label={
                      <div className="flex flex-row items-center space-x-2">
                        <span className="text-sm">Session Tracking</span>
                        <img alt="trackr" className="h-7" src={Trackr} />
                      </div>
                    }
                    name="enableTrackr"
                    onChange={onChange}
                    checked={value}
                    ref={ref}
                  />
                )}
              />
            )}
          </div>

          <Controller
            name="techNotes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RichTextInput
                id="techNotes"
                disabled={loading}
                onChange={onChange}
                placeholder="Add technical notes here."
                value={value}
              />
            )}
          />

          <Controller
            name="enableRegistrationSync"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                disabled={loading}
                label="This event includes an API configuration"
                onChange={onChange}
                value={value}
              />
            )}
          />

          {watch('enableLeadRetrieval') && (
            <div className="flex flex-col items-start space-y-4">
              <div className="flex flex-col items-start space-y-1">
                <span className="text-sm font-normal text-gray-500">
                  Control whether the following metrics can be displayed to Exhibitors.
                </span>

                <div className="flex flex-row space-x-8">
                  <Controller
                    name="displayAttendeeCount"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        disabled={loading}
                        label="Display Attendee Count"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />

                  <Controller
                    name="displayExhibitorCount"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CheckBox
                        disabled={loading}
                        label="Display Exhibitor Count"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
              </div>

              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-7 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<CurrencyDollarIcon className="ml-1.5 h-5 stroke-purple" />}
                data-testid="percentRevenueShare"
                disabled={loading}
                endIcon="%"
                error={errors.percentRevenueShare && 'This field is required'}
                fullWidth
                id="percentRevenueShare"
                inputStyles="rounded-none"
                name="percentRevenueShare"
                nunito
                label="Percent Revenue Share"
                placeholder="0"
                type="number"
                {...register('percentRevenueShare')}
              />
            </div>
          )}
        </div>
      }
      loading={loading}
      onClose={() => {
        setShowEventModal(false)

        setTimeout(() => {
          reset(DEFAULT)
        }, 500)
      }}
      open
      setOpen={setShowEventModal}
      title={editEvent ? 'Edit' : 'Add an Event'}
    />
  )
}

AddEventModal.defaultProps = {
  editEvent: null,
  organization: null,
  organizations: [],
}

AddEventModal.propTypes = {
  editEvent: PropTypes.object,
  loadData: PropTypes.func.isRequired,
  organization: PropTypes.object,
  organizations: PropTypes.array,
  setShowEventModal: PropTypes.func.isRequired,
}

export default AddEventModal
