import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Cog6ToothIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

// Components
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { DataTable } from '../../components/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'

// Icons
import Key from '../../assets/images/key.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Services
import { getEvent, updateEvent } from '../../services/events.service'
import { getOrganizationApiOptions } from '../../services/organizations.service'

// Utils
import { CVENT_STATUSES, STOVA_STATUSES } from '../../utils/constants'
import { toast } from '../../utils/helpers'

let interval = null

/**
 *
 * EventApiConfig
 *
 */
const EventApiConfig = observer(() => {
  // Context
  const { event, eventId, organizationId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [registrationOptions, setRegistrationOptions] = useState([])
  const [loadingRegistration, setLoadingRegistration] = useState(false)
  const [loadingReset, setLoadingReset] = useState(false)
  const [pingEvent, setPingEvent] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(false)
  const [showResetConfirm, setShowResetConfirm] = useState(false)

  // Custom Field Sync
  const [syncInProgress, setSyncInProgress] = useState(false)
  const [loadingCustomFields, setLoadingCustomFields] = useState(false)
  const [customFields, setCustomFields] = useState([])
  const [selectedFields, setSelectedFields] = useState([])

  // Category Sync
  const [availableCategories, setAvailableCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  // Status Sync
  const [availableStatuses, setAvailableStatuses] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  const hasCompletedRegistrationConfig = () => {
    if (!event || !event.registrationType) return false
    if (!event.externalEventId) return false

    const registrationType = _.find(registrationOptions, (o) => o.label === event.registrationType)
    if (registrationType === undefined) return false
    if (registrationType.type === 'API Key' && !event.externalAccountId && !event.apiKey)
      return false
    if (
      registrationType.type === 'OAuth 2.0 Client Credentials' &&
      (!event.clientId || !event.clientSecret)
    )
      return false
    return true
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      registrationType: null,
      apiKey: null,
      externalAccountId: null,
      externalEventId: null,
    },
  })

  useEffect(() => {
    const getOptions = async () => {
      const response = await getOrganizationApiOptions(organizationId, setError, setLoading)
      if (response) {
        setRegistrationOptions(
          _.map(_.keys(response), (system) => ({
            label: _.capitalize(system),
            id: system,
            type: response[system],
          })),
        )
      }
    }

    getOptions()
  }, [])

  /**
   * Triggers on first load to see if we need to monitor the registration status for this event.
   */
  useEffect(() => {
    if (
      event.enableRegistrationSync &&
      hasCompletedRegistrationConfig() &&
      !event.availableCustomFields
    ) {
      setPingEvent(true)
    }

    reset({
      registrationType: _.find(registrationOptions, (o) => o.label === event.registrationType),
      apiKey: event.apiKey,
      clientId: event.clientId,
      clientSecret: event.clientSecret,
      externalAccountId: event.externalAccountId,
      externalEventId: event.externalEventId,
    })
  }, [event, registrationOptions])

  useEffect(() => {
    if (event.availableCustomFields) {
      const fields = _.map(_.keys(event.availableCustomFields), (k) => ({
        name: event.availableCustomFields[k].text,
        key: k,
        ...event.availableCustomFields[k],
      }))

      setCustomFields(fields)
      setSelectedFields(event.enabledCustomFields || [])
    }

    const categories = _.map(_.keys(event.attendeeCategories), (key) => ({
      id: key,
      name: event.attendeeCategories[key],
    }))
    setAvailableCategories(categories)

    if (event.registrationType === 'Stova') {
      setAvailableStatuses(STOVA_STATUSES)
    } else if (event.registrationType === 'Cvent') {
      setAvailableStatuses(CVENT_STATUSES)
    }

    setSelectedCategories(event.enabledAttendeeCategories || [])
    setSelectedStatuses(event.enabledAttendeeStatuses || [])
  }, [event])

  useEffect(() => {
    if (pingEvent) {
      setCheckingStatus(true)

      if (interval === null) {
        interval = setInterval(async () => {
          const updatedEvent = await getEvent(organizationId, eventId)
          setEvent(updatedEvent)

          if (
            updatedEvent.availableCustomFields ||
            updatedEvent.attendeesSyncStatusMessage ||
            updatedEvent.customFieldsSyncStatusMessage
          ) {
            clearInterval(interval)
            setCheckingStatus(false)
            setPingEvent(false)
          }
        }, 3000)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [pingEvent])

  /**
   * Handles submitting the registration sync form to initiate the sync process.
   * @param {object} data
   */
  const onRegistrationSubmit = async (data) => {
    const updatedData = {
      ...data,
      registrationType: data.registrationType.label,
      triggerAttendeeSyncAllTime: true,
    }

    // If the api key includes `*`, then it is already set and
    // we don't want to update it.
    if (updatedData.apiKey?.includes('*')) {
      delete updatedData.apiKey
    }
    if (updatedData.clientId?.includes('*')) {
      delete updatedData.clientId
    }
    if (updatedData.clientSecret?.includes('*')) {
      delete updatedData.clientSecret
    }

    const result = await updateEvent(
      { id: eventId, ...updatedData },
      handleErrors,
      setLoadingRegistration,
      () => {},
    )

    if (result) {
      handleSuccesses('Registration sync initiated.')
      setPingEvent(true)
    }
  }

  /**
   * Handles submitting the merge fields form to update the selected custom fields.
   */
  const onMergeFieldsSubmit = async () => {
    const result = await updateEvent(
      {
        id: eventId,
        enabledAttendeeCategories: selectedCategories,
        enabledAttendeeStatuses: selectedStatuses,
        enabledCustomFields: selectedFields,
        triggerCustomFieldsSync: true,
      },
      handleErrors,
      setLoadingCustomFields,
      () => {},
    )

    if (result) {
      handleSuccesses('Merge field sync initiated.')
      setEvent(result)
      setSyncInProgress(true)
    }
  }

  /**
   * Handles submitting a reset registration request for the event.
   */
  const onResetSubmit = async () => {
    const result = await updateEvent(
      { id: eventId, triggerResetRegistrationSync: true },
      handleErrors,
      setLoadingReset,
      () => {},
    )

    if (result) {
      handleSuccesses('Registration sync reset.')
      setEvent(result)

      // Clear out all monitoring
      reset({
        registrationType: null,
        apiKey: null,
        clientId: null,
        clientSecret: null,
        externalAccountId: null,
        externalEventId: null,
      })
      setShowResetConfirm(false)
      setPingEvent(false)
      setCheckingStatus(false)
    }
  }

  const renderRegistrationConfigFields = () => {
    const fields = []

    const selected = watch('registrationType')
    if (!selected) return null

    const registrationOption = _.find(registrationOptions, (o) => o.id === selected.id)
    if (registrationOption?.type === 'API Key') {
      fields.push(
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          disabled={loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()}
          icon={<Cog6ToothIcon className="ml-1.5 h-5 stroke-purple" />}
          data-testid="externalAccountId"
          error={errors.externalAccountId && 'This field is required'}
          fullWidth
          id="externalAccountId"
          inputStyles="rounded-none font-nunito text-md sm:text-base"
          name="externalAccountId"
          nunito
          placeholder="Account ID"
          {...register('externalAccountId', { required: true })}
        />,
      )
      fields.push(
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          disabled={loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()}
          icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
          data-testid="api-key"
          error={errors.apiKey && 'This field is required'}
          fullWidth
          id="apiKey"
          inputStyles="rounded-none font-nunito text-md sm:text-base"
          name="apiKey"
          nunito
          placeholder="API Key"
          {...register('apiKey', { required: true })}
        />,
      )
    } else if (registrationOption?.type === 'OAuth 2.0 Client Credentials') {
      fields.push(
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          disabled={loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()}
          icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
          data-testid="client-id"
          error={errors.clientId && 'This field is required'}
          fullWidth
          id="clientId"
          inputStyles="rounded-none font-nunito text-md sm:text-base"
          name="clientId"
          nunito
          placeholder="Client ID"
          {...register('clientId', { required: true })}
        />,
      )
      fields.push(
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          disabled={loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()}
          icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
          data-testid="client-secret"
          error={errors.clientSecret && 'This field is required'}
          fullWidth
          id="clientSecret"
          inputStyles="rounded-none font-nunito text-md sm:text-base"
          name="clientSecret"
          nunito
          placeholder="Client Secret"
          {...register('clientSecret', { required: true })}
        />,
      )
    }

    return <div className="flex w-full flex-col gap-2 sm:flex-row">{fields}</div>
  }

  const syncError =
    event && (event.customFieldsSyncStatusMessage || event.attendeesSyncStatusMessage)

  return (
    <div className="h-full w-full">
      <StateContainer loading={loading} error={error}>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex flex-row place-items-center justify-between">
            <EventHeader event={event} />
          </div>

          <span className="text-md font-bold">API Config</span>

          <div className="shadow-xm flex w-full flex-col rounded-[15px] bg-white px-5 py-4">
            <div className="mb-4 flex flex-col">
              <div className="mb-2 flex flex-col items-start justify-between gap-1 sm:flex-row">
                <span className="text-md font-bold">Registration Details</span>

                <div className="flex flex-col items-center gap-2 sm:flex-row">
                  <div className="flex shrink-0 flex-col gap-1">
                    {event.attendeesLastSyncedAt && !event.attendeesSyncStatusMessage && (
                      <span className="text-xs">
                        Last Updated{' '}
                        {dayjs(event.attendeesLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                      </span>
                    )}

                    {event.attendeesSyncStatusMessage && (
                      <span className="text-end text-xs text-error">
                        {event.attendeesSyncStatusMessage}
                      </span>
                    )}
                  </div>

                  {hasCompletedRegistrationConfig() && (
                    <Button
                      className="border-red bg-white text-red hover:bg-white"
                      fullWidth
                      onClick={() => setShowResetConfirm(true)}
                      outlined
                      label="Reset API Connection"
                    />
                  )}
                </div>
              </div>

              <div className="flex w-full flex-col space-y-2">
                <div className="flex w-full flex-col gap-2 sm:flex-row">
                  <Controller
                    name="registrationType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="registrationType"
                        disabled={
                          loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()
                        }
                        error={errors.registrationType && 'This field is required'}
                        id="registrationType"
                        name="registrationType"
                        nunito
                        onChange={onChange}
                        options={registrationOptions}
                        placeholder="Select a Registration System"
                        style={{ flex: true, width: '100%' }}
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />

                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={
                      loadingRegistration || checkingStatus || hasCompletedRegistrationConfig()
                    }
                    icon={<Cog6ToothIcon className="ml-1.5 h-5 stroke-purple" />}
                    data-testid="externalEventId"
                    error={errors.externalEventId && 'This field is required'}
                    fullWidth
                    id="externalEventId"
                    inputStyles="rounded-none font-nunito text-md sm:text-base"
                    name="externalEventId"
                    nunito
                    placeholder="Event ID"
                    {...register('externalEventId', { required: true })}
                  />
                </div>

                {renderRegistrationConfigFields()}
              </div>

              {!hasCompletedRegistrationConfig() && (
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  className="mt-4 self-end"
                  loading={loadingRegistration}
                  label="Connect to Registration API"
                  onClick={handleSubmit(onRegistrationSubmit)}
                />
              )}
            </div>

            {pingEvent && !syncError && (
              <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                <span className="text-2xl font-bold">Syncing merge fields...</span>
                <span className="flex items-center pr-3">
                  <div className="h-10 w-10">
                    {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
                    <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                      <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
                    </svg>
                  </div>
                </span>
              </div>
            )}

            {event.availableCustomFields && (
              <div className="mt-4 flex h-full max-h-[400px] grow flex-col space-y-2 overflow-hidden">
                <div className="flex flex-col items-start justify-between space-y-1 sm:flex-row sm:space-y-0">
                  <span className="text-md font-bold">Available Merge Fields</span>

                  <div className="flex flex-col space-y-1">
                    <Tooltip
                      content={
                        <div className="rounded-lg bg-white px-2 pb-0.5">
                          <span className="text-xs">Sync is in progress.</span>
                        </div>
                      }
                      display={syncInProgress}
                      fullWidth
                      placement="left"
                    >
                      <Button
                        background="bg-purple border-purple hover:bg-purple-600"
                        disabled={syncInProgress}
                        fullWidth
                        label="Resync Configuration Options"
                        loading={loadingCustomFields}
                        onClick={onMergeFieldsSubmit}
                      />
                    </Tooltip>

                    {event.customFieldsLastSyncedAt && !event.customFieldsSyncStatusMessage && (
                      <span className="text-xs">
                        Last Updated{' '}
                        {dayjs(event.customFieldsLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                      </span>
                    )}

                    {event.customFieldsSyncStatusMessage && (
                      <span className="text-xs text-error">
                        {event.customFieldsSyncStatusMessage}
                      </span>
                    )}
                  </div>
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'select',
                      width: '135px',
                      name: (
                        <div className="flex flex-row">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (
                                selectedFields.length === 0 ||
                                selectedFields.length < customFields.length
                              ) {
                                setSelectedFields(_.map(customFields, 'key'))
                              } else {
                                setSelectedFields([])
                              }
                            }}
                            value={selectedFields.length === customFields.length}
                          />
                          <span className="text-xs uppercase">Select All</span>
                        </div>
                      ),
                      cell: (row) => (
                        <div className="">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (selectedFields.includes(row.name)) {
                                setSelectedFields(selectedFields.filter((f) => f !== row.key))
                              } else {
                                setSelectedFields([...selectedFields, row.key])
                              }
                            }}
                            value={selectedFields.includes(row.key)}
                          />
                        </div>
                      ),
                      sortable: false,
                    },
                    {
                      id: 'field',
                      grow: 1,
                      name: 'Field Name',
                      selector: (row) => row.name,
                      sortable: true,
                      width: '300px',
                    },
                    {
                      id: 'visibility',
                      grow: 1,
                      name: 'Visible',
                      selector: (row) => (row.questionId ? 'Yes' : 'No'),
                      sortable: true,
                      omit: event.registrationType !== 'Stova',
                    },
                  ]}
                  data={customFields}
                  defaultSortFieldId="name"
                  defaultSortAsc
                  progressPending={loadingCustomFields}
                  responsive
                />
              </div>
            )}

            {availableCategories && (
              <div className="mt-4 flex h-full max-h-[400px] grow flex-col space-y-2 overflow-hidden ">
                <div className="flex flex-col items-start justify-between space-y-1 sm:flex-row sm:space-y-0">
                  <span className="text-md font-bold">Available Categories</span>
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'select',
                      width: '135px',
                      name: (
                        <div className="flex flex-row">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (
                                selectedCategories.length === 0 ||
                                selectedCategories.length < availableCategories.length
                              ) {
                                setSelectedCategories(_.map(availableCategories, 'id'))
                              } else {
                                setSelectedCategories([])
                              }
                            }}
                            value={selectedCategories.length === availableCategories.length}
                          />
                          <span className="text-xs uppercase">Select All</span>
                        </div>
                      ),
                      cell: (row) => (
                        <div className="">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (selectedCategories.includes(row.id)) {
                                setSelectedCategories(
                                  selectedCategories.filter((f) => f !== row.id),
                                )
                              } else {
                                setSelectedCategories([...selectedCategories, row.id])
                              }
                            }}
                            value={selectedCategories.includes(row.id)}
                          />
                        </div>
                      ),
                      sortable: false,
                    },
                    {
                      id: 'category',
                      grow: 1,
                      name: 'Category Name',
                      selector: (row) => row.name,
                      sortable: true,
                    },
                  ]}
                  data={availableCategories}
                  defaultSortFieldId="name"
                  defaultSortAsc
                  progressPending={loadingCustomFields}
                  responsive
                />
              </div>
            )}

            {availableStatuses && (
              <div className="mt-4 flex h-full max-h-[400px] grow flex-col space-y-2 overflow-hidden ">
                <div className="flex flex-col items-start justify-between space-y-1 sm:flex-row sm:space-y-0">
                  <span className="text-md font-bold">Available Statuses</span>
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'select',
                      width: '135px',
                      name: (
                        <div className="flex flex-row">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (
                                selectedStatuses.length === 0 ||
                                selectedStatuses.length < availableStatuses.length
                              ) {
                                setSelectedStatuses(_.map(availableStatuses, 'id'))
                              } else {
                                setSelectedStatuses([])
                              }
                            }}
                            value={selectedStatuses.length === availableStatuses.length}
                          />
                          <span className="text-xs uppercase">Select All</span>
                        </div>
                      ),
                      cell: (row) => (
                        <div className="">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (selectedStatuses.includes(row.id)) {
                                setSelectedStatuses(selectedStatuses.filter((f) => f !== row.id))
                              } else {
                                setSelectedStatuses([...selectedStatuses, row.id])
                              }
                            }}
                            value={selectedStatuses.includes(row.id)}
                          />
                        </div>
                      ),
                      sortable: false,
                    },
                    {
                      id: 'status',
                      grow: 1,
                      name: 'Status Name',
                      selector: (row) => row.name,
                      sortable: true,
                    },
                  ]}
                  data={availableStatuses}
                  defaultSortFieldId="name"
                  defaultSortAsc
                  progressPending={loadingCustomFields}
                  responsive
                />
              </div>
            )}
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
          },
          {
            type: 'submit',
            label: 'Confirm',
            background: 'bg-red',
            onClick: () => onResetSubmit(),
          },
        ]}
        icon={<ExclamationTriangleIcon className="h-8 text-white" />}
        iconBackground="bg-red"
        content={
          <div className="mt-3 flex flex-col space-y-2 text-center sm:mt-5">
            <span className="text-sm">
              If you reset the API connection, all currently synced data will be deleted, including
              Merge Fields and Attendees.
            </span>

            <span className="text-sm">Do you want to continue and reset the API connection?</span>
          </div>
        }
        loading={loadingReset}
        open={showResetConfirm}
        setOpen={setShowResetConfirm}
        title="Are you sure?"
      />
    </div>
  )
})

export default EventApiConfig
