/**
 * Pixel Per Inch
 *
 * Panels are sized in inches and are based on the standard badge sizes. We
 * convert the inches to pixels for the canvas.
 */
export const PIXEL_PER_INCH = 96

/**
 * Countries that require the GDPR popup to be displayed.
 */
export const GDPR_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'The Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
]

/**
 * Base attendee fields that will always be avialable.
 */
export const NON_SYNCED_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'title',
  'companyName',
  'phoneNumber',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'zipCode',
]

export const BASE_RECIPIENT_FIELDS = [
  'recipientFirstName',
  'recipientLastName',
  'recipientEmail',
  'defaultLoginUrl',
  'exhibitorLoginUrl',
  'fetchLoginUrl',
]

/**
 * Available merge fields for each email template context.
 */
export const CONTEXT_MERGE_FIELDS = {
  Event: [
    'eventName',
    'eventLocation',
    'eventStartDate',
    'eventEndDate',
    'eventDateRange',
    'eventTechNotes',
  ],
  Organizer: ['organizerName'],
  'Event Exhibitor': [
    'exhibitorName',
    'exhibitorContactFirstName',
    'exhibitorContactLastName',
    'exhibitorContactEmail',
    'exhibitorTasksCompleted',
    'exhibitorTasksIncomplete',
    'exhibitorTasksTotal',
    'exhibitorTotalLicenses',
    'exhibitorAssignedLicenses',
    'exhibitorUnassignedLicenses',
  ],
  Attendee: ['attendeeFirstName', 'attendeeLastName', 'attendeeCompanyName', 'attendeeEmail'],
  'Assigned By': ['assignedByFirstName', 'assignedByLastName', 'assignedByEmail'],
  'Scanned By': ['scannedByFirstName', 'scannedByLastName', 'scannedByEmail'],
  Transaction: ['transactionLicenseCount', 'transactionRentalDeviceCount'],
  'Get Started': ['getStartedURL'],
}

export const STOVA_STATUSES = [
  {
    name: 'Confirmed',
    id: 'confirmed',
  },
  {
    name: 'Attended',
    id: 'attended',
  },
  {
    name: 'No Show',
    id: 'no_show',
  },
  {
    name: 'Cancelled',
    id: 'cancelled',
  },
  {
    name: 'Activated',
    id: 'activated',
  },
  {
    name: 'Waitlist',
    id: 'waitlist',
  },
  {
    name: 'Declined',
    id: 'declined',
  },
  {
    name: 'Incomplete',
    id: 'incomplete',
  },
  {
    name: 'Inactive',
    id: 'inactive',
  },
  {
    name: 'In progress',
    id: 'in_progress',
  },
]

export const CVENT_STATUSES = [
  {
    name: 'Accepted',
    id: 'accepted',
  },
  {
    name: 'Cancelled',
    id: 'cancelled',
  },
  {
    name: 'Declined',
    id: 'declined',
  },
  {
    name: 'Denied',
    id: 'denied',
  },
  {
    name: 'Invited',
    id: 'invited',
  },
  {
    name: 'No Response',
    id: 'no_response',
  },
  {
    name: 'Pending Approval',
    id: 'pending_approval',
  },
  {
    name: 'Visited',
    id: 'visited',
  },
  {
    name: 'Waitlisted',
    id: 'waitlisted',
  },
]
